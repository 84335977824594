.p-multiselect-token {
  align-items: center !important;
  padding: 0.25rem 0.75rem !important;
  margin: 0.1rem 0.4rem 0.1rem 0 !important;
  color: #3f4254 !important;
  background: #f3f6f9 !important;
  border-radius: 0.42rem !important;
}

.p-multiselect-panel .p-multiselect-item {
  color: #3f4254 !important;
}

.p-multiselect-panel .p-multiselect-items:not(.p-multiselect-virtualscroll) {
  padding: 0;
}
