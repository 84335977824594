// Metronic styles
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "./assets/@metronic/sass/style.angular.scss";

// PrimeNg styles
@import "~primeng/resources/themes/bootstrap4-light-blue/theme.css";
@import "~primeng/resources/primeng.min.css";
@import "~primeflex/primeflex.min.css";
@import "~primeicons/primeicons.css";

// Default Layout themes
@import "./assets/@metronic/sass/themes/layout/header/base/light.scss";
@import "./assets/@metronic/sass/themes/layout/header/menu/light.scss";
@import "./assets/@metronic/sass/themes/layout/brand/dark.scss";
@import "./assets/@metronic/sass/themes/layout/aside/dark.scss";

// themes for metronic template
@import "./assets/sass/themes/index.scss";

// Country flags
@import "../node_modules/flag-icon-css/assets/docs.css";
@import "../node_modules/flag-icon-css/css/flag-icon.css";

//reset styles
@import "./assets/sass/override.scss";
@import "./assets/sass/shared.scss";
