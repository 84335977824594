.p-orderlist-controls {
  display: none !important;
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #ffffff;
  background: #f7f7f7;
}

.p-orderlist .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover {
  background: #eef1f37a;
  color: #212529;
}
