.file-uploader__content {
  text-align: center;
  img {
    height: 64px;
    width: 64px;
    opacity: 0.4;
  }
  .max-file__size {
    background-color: rgba(0, 69, 255, 0.1);
    color: #0045ff;
    padding: 8px;
    border-radius: 3px;
  }
}
.preview__img {
  width: 25%;
  height: 6rem;
  border-radius: 2px;
}
.delete_preview__img {
  width: calc(1.35em + 1.1rem + 2px) !important;
  margin-left: 5px;
  margin-bottom: -42px;
  .p-button-label {
    display: none;
  }
}
app-empty-content {
  width: 100%;
}
.w-40px {
  width: 40px;
}
.w-60px {
  width: 60px;
}
.btn-refresh {
  display: flex;
  justify-content: center;
  border: 1px solid #e4e6ef !important;
  &:hover {
    border: 1px solid #e4e6ef !important;
    background: #f3f6f9 !important;
  }
  fa-icon {
    &:hover {
      cursor: pointer;
    }
  }
  .p-button-label {
    display: none;
  }
}
.d-grid {
  display: grid;
}
.badge.status-Planned {
  color: #ffa800;
  background-color: #fff4de;
}
.badge.status-Passive {
  background-color: #ebedf3;
  color: #3f4254;
}
.badge.status-Active {
  color: #1bc5bd;
  background-color: #c9f7f5;
}
.menu-label {
  a {
    font-size: 1.25rem;
    color: #b5b5c3;
  }
}

#kt_aside_menu_wrapper {
  fa-icon {
    font-size: 1.3rem;
    color: #b5b5c3;
  }
}
#kt_content {
  .content {
    min-height: 80vh;
  }
}

#kt_footer {
  left: 0px;
  bottom: 0;
  width: 100%;
  margin-top: 2rem;
  z-index: -1;
}

.height-100vh {
  height: 100vh;
}

.word-break {
  word-break: break-all;
}

.shadow-bg-kt-header {
  background-color: #8f9095 !important;
}

.normal-bg-kt-header {
  background-color: #eef0f8 !important;
}

.img-container-in-list {
  height: 50px !important;
  width: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: auto;
    width: auto;
    max-width: 50px;
    max-height: 50px;
  }
}

@media only screen and (max-width: 768px) {
  #kt_footer {
    margin-bottom: 2rem;
  }
  #kt_subheader_container {
    padding-left: 0;
  }
}

@media only screen and (max-width: 991.98px) {
  #kt_subheader_container {
    padding-left: 0;
  }
}

.font-size-10px {
  font-size: 10px !important;
}

.p-dialog .p-dialog-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-component {
  font-family: 'Poppins', Helvetica, 'sans-serif';
}

.p-inline-message.p-inline-message-warn {
  background: #ffa800;
  border: solid #ffa800;
  border-width: 0px;
  color: #fff;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.42rem;
  justify-content: initial;
}

.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #ffffff;
  font-size: 2.4rem;
  opacity: 0.8;
}
.alert.alert-custom .alert-icon {
  font-size: 2.4rem;
}

.small-dynamic-dialog {
  width: 25vw;
}

.medium-dynamic-dialog {
  width: 42vw;
}

.large-dynamic-dialog {
  width: 50vw;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .small-dynamic-dialog {
    width: 35vw;
  }

  .medium-dynamic-dialog {
    width: 64vw;
  }
}

@media (max-width: 992px) {
  .small-dynamic-dialog {
    width: 40vw;
  }

  .medium-dynamic-dialog {
    width: 64vw;
  }

  .large-dynamic-dialog {
    width: 70vw;
  }
}

@media (max-width: 768px) {
  .small-dynamic-dialog {
    width: 52vw;
  }

  .medium-dynamic-dialog {
    width: 85vw;
  }

  .large-dynamic-dialog {
    width: 85vw;
  }
}

@media (max-width: 576px) {
  .small-dynamic-dialog {
    width: 85vw;
  }

  .medium-dynamic-dialog {
    width: 90vw;
  }

  .large-dynamic-dialog {
    width: 90vw;
  }
}

.z-index-1 {
  z-index: 1;
}

.tree-node-focused {
  background-color: #007bff !important;
  color: white !important;
  border-radius: 4px;
  &:hover {
    background-color: #007bff !important;
  }
}

.p-treenode-label b {
  font-weight: 400;
}

.word-break-all {
  word-break: break-all;
}

.word-break {
  word-break: break-word;
}

.p-blockui.p-component-overlay {
  background: rgb(78 78 78 / 12%) !important;
}

.-mt-1px {
  margin-top: -1px;
}

.w-13rem {
  width: 13rem !important;
}

.apps-cards-description {
  min-height: 10.4rem;
  max-height: 10.4rem;
  overflow-y: auto;
}

.apps-cards-description::-webkit-scrollbar {
  width: 5px;
}

.apps-cards-description::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.apps-cards-description::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.pulse {
  animation-name: pulse;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

// fix the editor select problem on iphone devices
.ql-editor {
  -webkit-user-select: text;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}
