.p-chip {
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
  &:hover {
    cursor: pointer;
  }
}

.p-chip-text {
  margin-bottom: 0.25rem;
  padding: 4px 1rem;
}

.pi-chip-remove-icon {
  margin-right: 5px;
  margin-left: 0;
}

.active-chip {
  background: var(--primary-color);
  color: var(--primary-color-text);
}
