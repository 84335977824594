.p-datatable-thead {
  display: table-caption;

  tr,
  th {
    font-weight: 700;
    color: #b5b5c3 !important;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    background-color: #f3f6f9 !important;
    border-bottom: none !important;
    border-top: none !important;
  }
}

.p-datatable .p-paginator-bottom {
  border-width: 0;
}

@media screen and (max-width: 40em) {
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
    width: auto !important;
  }
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #cad1d82e !important;
  color: #ffffff;
}

.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  word-break: break-all !important;
}
