.p-paginator {
  justify-content: flex-end !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  color: #ffffff !important;
  background-color: #3699ff !important;
  border-color: #3699ff !important;
  margin: 0.25rem 0.5rem 0.25rem 0;
  border-radius: 0.42rem;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  color: #7e8299;
  background-color: #e4e6ef;
  border-color: #e4e6ef;
  margin: 0.25rem 0.5rem 0.25rem 0;
  border-radius: 0.42rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  color: #7e8299;
  background-color: #e4e6ef;
  border-color: #e4e6ef;
  margin: 0.25rem 0.5rem 0.25rem 0;
  border-radius: 0.42rem;
}
