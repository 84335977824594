.p-dropdown {
  padding: 2px;
  border: 1px solid #e4e6ef;
}

@media (min-width: 205px) {
  .dropdown-menu.dropdown-menu-sm {
    width: 183px;
  }
}
