@import './primeng/index.scss';
.dropdown-toggle::after {
  display: none !important;
}
.angular-bootstrap-table {
  overflow-x: auto;
}
.angular-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead th.sortable-active {
    color: #3699ff !important;
  }
}
.cursor-default {
  cursor: default !important;
}
.progress-modal {
  height: 3px;
  width: 100%;
}
.shopiroller-logo {
  height: 1.936rem;
  width: auto;
}
#kt_brand {
  z-index: 100;
}
.navi .navi-item .navi-link .navi-text {
  font-size: 0.95rem;
}
#search-icon {
  filter: invert(48%) sepia(44%) saturate(2585%) hue-rotate(192deg)
    brightness(103%) contrast(101%);
}
.modal-body::-webkit-scrollbar {
  width: 4px;
  border-radius: 0.42rem !important;
}
.modal-body::-webkit-scrollbar-track {
  background: #1e1e2d;
  opacity: 1;
}
.modal-body::-webkit-scrollbar-thumb {
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 6px;
  right: 2px;
  &:hover {
    background-color: #637099;
  }
}
mat-dialog-container {
  padding: 0 !important;
  .form-group {
    padding: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 1.75rem !important;
  }
}
.close {
  &:hover {
    color: #3699ff !important;
  }
  .ki {
    font-size: 0.8rem;
    &:hover {
      color: #3699ff !important;
    }
  }
}
.custom-spinner {
  right: 0 !important;
  left: auto !important;
  transform: scale(0.8);
}
select > option.hidden_option {
  display: none;
}
.aside-light .aside {
  box-shadow: none;
}
#kt_aside_menu_wrapper {
  background-color: #fff;
  width: inherit;
  left: 1px;
}
.aside-menu-wrapper {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}
.aside-light .aside-menu {
  background-color: #fff;
}

#kt_header_mobile {
  img {
    height: 30px;
    width: 23px;
  }
}

.cursor-pointer {
  cursor: pointer;
}
.btn-hover-light-primary {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.navi.navi-hover .navi-item .navi-link:hover {
  background-color: #f3f6f9;
}
.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  color: #3699ff;
}
.mat-menu-item {
  color: #3f4254;
  &:hover {
    color: #3699ff !important;
    fa-icon {
      color: #3699ff !important;
    }
  }
  fa-icon {
    color: #b5b5c3;
  }
}
.btn-google-auth {
  background-color: #006cf9 !important;
  color: white;
  span {
    img {
      border: 3px solid #fff;
      border-radius: 14px;
      background-color: #fff;
    }
  }
}
.select2-closed {
  border: 1px solid #69b3ff !important;
  border-radius: 5px !important;
  padding: 0px 0.65rem;
}
.align-to-right {
  margin-left: auto;
  margin-right: 0;
}
.switch-row {
  display: flex;
  flex-direction: row;
}
.switch-column {
  display: flex;
  flex-direction: column;
}
.custom_button_icon .p-button-label {
  display: none;
}

@media (min-width: 992px) {
  .header.header-fixed {
    position: absolute;
    z-index: 3;
    top: 0;
  }

  .header-base-light .header {
    background-color: #eef0f8;
  }

  .header-fixed.subheader-fixed .subheader {
    border-top: 0;
    background-color: #eef0f8;
    position: absolute;
    z-index: 2;
    box-shadow: none;
  }

  .header-fixed.aside-static .brand {
    width: 265px;
    border-bottom: 1px solid #f3f3f3;
  }
  #kt_aside_menu_wrapper {
    position: fixed;
  }
}

@media (max-width: 992px) {
  #kt_header {
    display: none;
  }
}

.aside-light .aside {
  box-shadow: 0px 0px 28px 0px rgb(82 63 105 / 8%) !important;
  -webkit-box-shadow: 0px 0px 28px 0px rgb(82 63 105 / 8%) !important;
}
.aside-menu .menu-nav {
  margin-left: -1px;
}
.quick-search.quick-search-dropdown {
  padding: 1rem 0.78rem;
}

.p-editor-container .p-editor-content .ql-editor {
  word-break: break-all;
}

/***************** Override PrimeNG Table Styles ***********************/
.p-datatable .p-datatable-tbody > tr > td {
  border: 2px dashed #ebedf3;
  border-width: 0 0 1px 0;
  padding: 0.75rem;
}

.p-datatable .p-datatable-tbody > tr {
  &:last-child {
    td {
      border-width: 0 0 0 0;
    }
  }
}
.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f3f6f952;
}

table::-webkit-scrollbar {
  width: 4px;
}

.p-datatable .p-datatable-tbody > tr:focus {
  outline: none;
}

/* Works on Chrome, Safari, and Edge */

::-webkit-scrollbar-track {
  background: #f3f6f9;
  opacity: 0;
  border-radius: 0.42rem !important;
}

::-webkit-scrollbar-thumb {
  background: #ecedf3;
  opacity: 1;
  border-radius: 0.42rem !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #dbdef3;
  opacity: 1;
  cursor: pointer;
}

/***************** Override Checkbox Background Color ***********************/
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #009ef7;
  background: #009ef7;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #009ef7;
  background: #009ef7;
}

/***************** OVERRIDE PRIME_NG CHIP STYLES ***********************/
.active-chip {
  background: #3699ff !important;
}

/*****************  OVERRIDE PRIME_NG BUTTON STYLES  ***********************/
.p-button {
  background: #3699ff;
  border: 1px solid #3699ff;
}

/*****************  OVERRIDE PRIME_NG P_COMPONENT STYLES  ***********************/
.p-component {
  font-family: sans-serif;
}

/*****************  OVERRIDE PRIME_NG P_CHIPS STYLES  ***********************/
.p-chips .p-chips-multiple-container .p-chips-token {
  border-radius: 16px;
  padding: 7px 0.75rem;
  background: #3699ff;
}

/*****************  OVERRIDE PRIME_NG TOAST MESSAGES STYLES  ***********************/
/*****************  p-toast-message-success  ***********************/
.p-toast .p-toast-message.p-toast-message-success {
  background-color: #1bc5bd;
  border: 1px solid #1bc5bd;
  color: #fff;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: white;
}
/*****************  p-toast-message-error  ***********************/
.p-toast .p-toast-message.p-toast-message-error {
  background: #f8d7da;
  border: 1px solid #f8d7da;
  border-width: 1px;
  color: #f64e60;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #f64e60;
}
/*****************  p-toast-message-info  ***********************/
.p-toast .p-toast-message.p-toast-message-info {
  background: #e1f0ff;
  border: 1px solid #e1f0ff;
  border-width: 1px;
  color: #3699ff;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #3699ff;
}
