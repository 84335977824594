.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 0;
}

.p-inputgroup-addon {
  background: #f3f6f9;
  color: #3f4254;
}

.p-inputnumber-buttons-stacked .p-inputnumber-button-group .p-button.p-inputnumber-button {
  color: #3f4254;
  background-color: #e4e6ef;
  border-color: #e4e6ef;
}
.p-inputwrapper-filled .p-inputnumber {
  width: 100% !important;
}
